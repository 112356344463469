import React from 'react';
import { Form, Button, Upload, Icon, Select} from 'antd';
import axios from 'axios';

//const { TextArea } = Input;
const { Option } = Select;
class CustomForm extends React.Component{

  handleFormSubmit = async(event, requestType, productID) =>{
      event.preventDefault();

      const imageObj = {
        image : event.target.elements.image.value
      }
        if (requestType === "post") {
        await axios.post('https://api.kuara-lite.com/api/product/',imageObj)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/image/`);
            }
          })
          .catch(err => console.log(err));

        } else if (requestType === "put") {
        await axios.put(`https://api.kuara-lite.com/api/product/${productID}`,imageObj)
          .then(res =>{
            if (res.status === 201) {
                this.props.history.push(`/image/`);
            }
          })
          .catch(err => console.log(err));
      }
  }
  render() {

    return (
      <div>
      <Form onSubmit={(event) =>
          this.handleFormSubmit(
            event,
            this.props.requestType,
            this.props.productID
          )}>
          <Form.Item label="Select" hasFeedback>

          <Select placeholder="Please select a product">
            <Option value="IDproduct"></Option>
          </Select>,
          </Form.Item>

          <Form.Item label="Image">
            <Upload img class="image" click="file.click()">
              <Button file type="file" change="onFileChanged($event)">
                <Icon type="file-image"/> Click to Image
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">{this.props.btnText}</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default CustomForm;
