import React from 'react';
import axios from 'axios';
import Project from '../components/Project';

class ProjectList extends React.Component {

    state = {
      projects : []
    };

    componentDidMount(){
        axios.get('https://api.kuara-lite.com/api/projects/')
        .then(res => {
            this.setState({
              projects: res.data
            });
          //  console.log(res.data);
          })
    }

    render(){
        return(
          <div>

            <br />
             <h2>Project List</h2>
              <Project data={this.state.projects} />
            <br />
          </div>
        )
    }
}

export default ProjectList;
