import React, { Component } from 'react'
import BlogEntry from '../components/BlogEntry'
import API from '../blogAPI'

class BlogListView extends Component {
    
    state = {
        entries: []
    }

    componentDidMount() {
        API.get('/')
        .then(res => {
            this.setState({entries: res.data})
        })
        .catch(err => {
            console.log(err)
        })
    }

    render(){

        const entries = this.state.entries
        const entryList = entries.map(entry => {
            return(
                <BlogEntry key={entry.id} data={entry}/>
            )
        })

        return(
            <div>
                <h2>Blog entries</h2>
                {entryList}
            </div>
        )
    }
}

export default BlogListView