import React from 'react';
import { List } from 'antd';
//se quito Avatar de antd

const Category = (props) =>{;

    return(
      <List
        itemLayout="vertical"
        size="large"
        pagination={{
          onChange: page => {
            console.log(page);
          },
          pageSize: 4,
        }}
        dataSource={props.data}
        footer={
          <div>
            <b> </b>
          </div>
        }
        renderItem={item => (
          <List.Item
            key={item.name}
            actions={[
            //  <IconText type="star-o" text="156" />,
              //<IconText type="like-o" text="156" />,
              //<IconText type="message" text="2" />,
            ]}
            extra={
              <img
                width={272}
                height={150}
                alt="logo"
              /*  src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"*/
                src={`${item.image}`}
              />
            }
          >
            <List.Item.Meta
            //  avatar={<Avatar src={item.avatar} />}
              name={<a href={`/category/${item.id}`}>{item.name}</a>}
              description={item.description}
              image={item.image}
              icon={item.icon}
            />
            {item.content}
            
          </List.Item>
        )}
      />
    )
}

export default Category;
