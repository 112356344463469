import React, { Component } from 'react';
import { List, Button, Icon, Modal } from 'antd';
import axios from 'axios'
import API from '../blogAPI'
import { Redirect } from 'react-router-dom';

const AUTH_URL = 'https://api.kuara-lite.com/rest-auth/login/'

class Blog extends Component {

    state = {
        data: this.props.data,
        modalVisible: false,
        clickedDelete: false,
        clickedEdit: false
    }

    handleEdit = () => {
        this.setState({
            clickedEdit: true
        })
    }

    handleDelete = () => {
        //console.log(`Deleting ${this.state.data.id}`)
        axios.post(AUTH_URL, {username: 'dacodes', password:'Kuara123!'})
            .then(res => {
                API.delete(`/${this.state.data.id}/`, {
                    headers: {
                        'Authorization':'token ' + res.data.key,
                        'Content-Type':'application/json'
                    }
                // Redirect after submission
                }).then(res => {this.setState({clickedDelete: true})}).catch(err=>console.log(err))
            })
    }

    handleModal = () => {
        this.setState({
            modalVisible: !this.state.modalVisible
        })
    }

    /*
    componentDidMount() {
        this.setState( {
            data: this.props.data
        })
    }*/
   
    render(){
        if (this.state.clickedEdit) {
            const path = '/blog/edit/' + this.state.data.id
            return <Redirect to={path}/>
        }
        if (this.state.clickedDelete) {
            return <Redirect to="/blog/"/>
        }
        return(
            <div>
                <List key={this.state.data.id}>
                    <List.Item>
                        <List.Item.Meta 
                            title={this.state.data.title}
                            description={this.state.data.short}
                        />
                    </List.Item>
                    <List.Item>
                        <Button onClick={this.handleEdit.bind(this)}>
                            <Icon type="edit"/> Edit
                        </Button>
                        <Button onClick={this.handleModal.bind(this)} type="danger" style={{marginLeft: '5px'}}>
                            <Icon type="delete"/> Delete
                        </Button>
                    </List.Item>
                </List>
                <br />
                <Modal
                    title="Are you sure you want to delete this post?"
                    visible={this.state.modalVisible}
                    onOk={this.handleDelete.bind(this)}
                    onCancel={this.handleModal.bind(this)}
                >
                    <p>{this.state.data.title}</p>
                </Modal>
            </div>
        )
    }
}

export default Blog;
