import React,{Component} from 'react';
import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import BaseRouter from './routes';
import {BrowserRouter as Router} from 'react-router-dom';

import CustomLayout from './containers/Layout';

class App extends Component {
	render(){
		return (
			<div className="App">
					<Router>
						<CustomLayout {...this.props}>
							<BaseRouter />
						</CustomLayout>
					</Router>
			</div>
		);
	}
}

export default App;
