import React,{Component} from 'react';
import CustomForm from '../components/FormCategorie';

class CreateCategorie extends Component{
    render(){
        return(
            <div>
            <h2>Create Category</h2>
            <CustomForm
              {...this.props}
              requestType="post"
              categoriesID={null}
              btnText="Create"
              />
            </div>
        );
    }
  
  }
  
  export default CreateCategorie
  ;