import React from 'react';
import axios from 'axios';
import Product from '../components/Product';

class ProductList extends React.Component {

	state = {
		products : []
	};

	componentDidMount(){
		axios.get('https://api.kuara-lite.com/api/products/')
		.then(res => {
			console.log(res)
			
			for (var i = 0; i < res.data.length; i++) {
				/*if (res.data[i].images.length > 0) {
					res.data[i].images = res.data[i].images[0].file
				} else {
					res.data[i].images = "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
				}*/
				res.data[i].images = res.data[i].thumbnail;
			}
			
			this.setState({
				products: res.data
			});
		})
	}

	render(){
		return(
			<div>
				<br />
				<h2>Product List</h2>
				<Product data={this.state.products} />
				<br />
			</div>
		)
	}
}

export default ProductList;
