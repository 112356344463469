import React from 'react';
import axios from 'axios';

import {Button, Card} from 'antd';
import CustomForm from '../components/FormProject';

class ProjectDetail extends React.Component {

    state = {
      project : {}
    };

  componentDidMount(){
      const projectID = this.props.match.params.projectID;
        axios.get(`https://api.kuara-lite.com/api/projects/${projectID}`)
        .then(res => {
            this.setState({
              project: res.data
            });
            //console.log(res.data);
          });
    }
    handleDelete = (event) => {
        event.preventDefault();
         const projectID = this.props.match.params.projectID;
         axios.delete(`https://api.kuara-lite.com/api/projects/${projectID}`)
         .then(res =>{
           if (res.status === 204) {
                this.props.history.push(`/project/`);
           }
         })
         .catch(err => console.log(err));
     }
    render(){
        return(
          <div>
            <Card title = {this.state.project.title}>
              <p>{this.state.project.description}</p>
              <p>{this.state.project.location}</p>
              </Card>
            <br />
            <CustomForm
                {...this.props}
                requestType="put"
                projectID={this.props.match.params.projectID}
                btnText="Update"
            />
            <form onSubmit={this.handleDelete}>
              <Button type="danger" htmlType="submit">Delete</Button>
            </form>
          </div>
        )
    }
}

export default ProjectDetail;
