import React,{Component} from 'react';
import axios from 'axios';
import Category from '../components/Category';

class CategoryList extends Component {
  state = {
    categories : []
  };

  componentDidMount(){
    axios.get('https://api.kuara-lite.com/api/categories/')
      .then(res => {
        this.setState({
          categories: res.data
        });
        //console.log(res.data);
      })
  }

  render(){
    return(
      <div>
        <br />
        <h2>Category List</h2>
          <Category data={this.state.categories} />
        <br />
      </div>
    )
  }
}

export default CategoryList;
