import React,{Component} from 'react';
import CustomForm from '../components/Form.js';

class CreateProduct extends Component{
  render(){
      return(
          <div>
          <h2>Create Product</h2>
          <CustomForm
            {...this.props}
            requestType="post"
            productID={null}
            btnText="Create"
            />
          </div>
      );
  }

}

export default CreateProduct;
