import React, { Component } from 'react';
import FormBlog from '../components/FormBlog';

class CreateBlog extends Component {

    render(){
        return(
            <div>
                <h2>New blog entry</h2>
                <FormBlog
                    method="POST"
                />
            </div>
        )
    }
}

export default CreateBlog