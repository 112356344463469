import React, {Component} from 'react'
import FormBlog from '../components/FormBlog'
import API from '../blogAPI'

class EditBlog extends Component {
    
    state = {
        title: null,
        desc: null,
        body: null
    }

    componentDidMount() {
        API.get(`/${this.props.match.params.id}/`)
            .then(res => {
                this.setState({
                    title:res.data.title,
                    desc:res.data.short,
                    body:res.data.content
                })
            })
    }

    render() {
        return(
            <div>
                <h2>Editing: <span style={{color: '#aaa'}}>{this.state.title}</span></h2>
                <FormBlog 
                    id={this.props.match.params.id}
                    oldTitle={this.state.title}
                    oldDesc={this.state.desc}
                    oldBody={this.state.body}
                    method="PATCH"
                />
            </div>
        )
    }
}

export default EditBlog