import React, { Component } from 'react';
import CustomForm from '../components/FormProject';

class CreateProject extends Component{

  render(){
    return(
        <div>
          <h2>Create Project</h2>
          <CustomForm
            {...this.props}
            requestType="post"
            projectID={null}
            btnText="Create"
          />
        </div>
    );
  }
}

export default CreateProject;
