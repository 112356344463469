import  React from 'react';
import { Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Login from './components/Login'

import ProductList from './containers/ProductListView';
import ProductDetail from './containers/ProductDetailView';

import ProjectList from './containers/ProjectListView';
import ProjectDetail from './containers/ProjectDetailView';

import CreateProduct from './containers/CreateProductView';
import CreateProject from './containers/CreateProjectView';
import CreateCategorie from './containers/CreateCategorieView';
import CreateImage from './containers/CreateImageView';
import CreateBlog from './containers/CreateBlog';

import CategoryList from './containers/CategoryListView';

import BlogList from './containers/BlogListView';
import EditBlog from './containers/EditBlog'


function BaseRouter(props){
    return(
        <div>
            <Route exact path='/login/' component={Login} />{" "}

            <PrivateRoute exact path='/' component={ProductList} />{" "}

            <PrivateRoute exact path ='/product/:productID/' component ={ProductDetail} />{" "}
            <PrivateRoute exact path ='/productcreate/' component = {CreateProduct}/>{" "}
            <PrivateRoute exact path='/imagecreate/' component={CreateImage} />{" "}

            <PrivateRoute exact path='/category/' component={CategoryList} />{" "}
            <PrivateRoute exact path='/categoriescreate/' component={CreateCategorie} />{" "}

            <PrivateRoute exact path='/project/' component={ProjectList} />{" "}
            <PrivateRoute exact path ='/project/:projectID/' component ={ProjectDetail} />{" "}
            <PrivateRoute exact path='/projectcreate/' component={CreateProject} />{" "}

            <PrivateRoute exact path='/blog/' component={BlogList} />
            <PrivateRoute exact path='/blog/create/' component={CreateBlog} />
            <PrivateRoute exact path='/blog/edit/:id' component={EditBlog}/>

            <PrivateRoute exact path='/admin/' component={CreateBlog}/>
        </div>
    )
}

export default BaseRouter;
