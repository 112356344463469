import React,{Component} from 'react';
import CustomForm from '../components/FormImage.js';

class CreateImage extends Component{
  render(){
      return(
          <div>
          <h2>Create Image</h2>
          <CustomForm
            {...this.props}
            requestType="post"
            productID={null}
            btnText="Create"
            />
          </div>
      );
  }

}

export default CreateImage;
