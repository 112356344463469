import React, { Component } from 'react';
import { Form, Input, Button, Spin } from 'antd';

import axios from 'axios'
import API from '../blogAPI'

// Froala 
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';

import 'froala-editor/js/plugins/align.min.js'
import 'froala-editor/js/plugins/colors.min.js'
import 'froala-editor/css/plugins/colors.min.css'
import 'froala-editor/js/plugins/font_family.min.js'
import 'froala-editor/js/plugins/font_size.min.js'
import 'froala-editor/js/plugins/fullscreen.min.js'
import 'froala-editor/css/plugins/fullscreen.min.css'
import 'froala-editor/js/plugins/image.min.js'
import 'froala-editor/css/plugins/image.min.css'
import 'froala-editor/js/plugins/link.min.js'
import 'froala-editor/js/plugins/lists.min.js'
import FroalaEditor from 'react-froala-wysiwyg';
import { Redirect } from 'react-router-dom';

const AUTH_URL = 'https://api.kuara-lite.com/rest-auth/login/'
const IMAGE_UPLOAD_URL = 'https://api.kuara-lite.com/froala_editor/image_upload/'

const { TextArea } = Input

const TITLE_MAX_CHARS = 100
const DESC_MAX_CHARS = 250

class FormBlog extends Component {

    state = {
        title: null,
        description: null,
        body: null,
        coverImage: null,
        titleCharsLeft: TITLE_MAX_CHARS,
        descCharsLeft: DESC_MAX_CHARS,
        hasSubmitted: false,
        isLoading: false
    }

    handleOnChange = (e) => {
        e.preventDefault()
        let name = e.target.name
        let val = e.target.value
        if (name === "title") {
            this.setState({
                titleCharsLeft: TITLE_MAX_CHARS - val.length,
                title: val
            })
        } else if (name === "desc") {
            this.setState({
                descCharsLeft: DESC_MAX_CHARS - val.length,
                description: val
            })
        } else if (name === "coverImage") {
            this.setState({
                coverImage: e.target.files[0]
            })
        }
    }

    handleModelChange = (model) => {
        this.setState({
            body:model
        })
    }

    handleSubmission = (e) => {

        e.preventDefault()
        this.setState({isLoading: true})

        let data = new FormData()
        if(this.state.title){
            data.set("title", this.state.title)
        }

        if(this.state.description){
            data.set("short", this.state.description)
        }

        if(this.state.coverImage){
            data.append("thumbnail", this.state.coverImage)
        }

        if(this.state.body){
            data.set("content", this.state.body)
        }

        //data.set("title", this.state.title)
        //data.set("short", this.state.description)
        //data.append("thumbnail", this.state.coverImage)
        //data.set("content", this.state.body)

        if(this.props.method === "POST") {
            // Get the auth token
            axios.post(AUTH_URL, {username: 'dacodes', password:'Kuara123!'})
            .then(res => {
                const key = res.data.key
                // Make the actual POST request
                API.post('/', data, {
                    headers: {
                        'Authorization':'token ' + key,
                        'Content-Type':'application/json'
                    }
                // Redirect after submission
                }).then(res => {this.setState({hasSubmitted: true})}).catch(err=>console.log(err))
            })
        } else if (this.props.method === "PATCH") {
            // Get the auth token
            axios.post(AUTH_URL, {username: 'dacodes', password:'Kuara123!'})
            .then(res => {
                const key = res.data.key
                // Make the actual PATCH request
                API.patch(`/${this.props.id}/`, data, {
                    headers: {
                        'Authorization':'token ' + key,
                        'Content-Type':'application/json'
                    }
                // Redirect after submission
                }).then(() => {this.setState({hasSubmitted: true})}).catch(err=>console.log(err))
            })
        }
    }

    // Update the body to be the old body  
    componentDidUpdate(prevProps) {
        if (this.props.oldBody !== prevProps.oldBody) {
            this.setState({
                body: this.props.oldBody
            })
        }
        
    }

    render() {
        if (this.state.hasSubmitted) {
            return <Redirect to="/blog/"/>
        }

        let labels = ['Title:', 'Short description:', 'Body:', 'Cover image:']
        if(this.props.method === "PATCH"){
            labels = ['New title:', 'New short description:', 'Edit body:', 'New cover image:']
        }
        
        let form = (
            <Form onSubmit={this.handleSubmission.bind(this)}>
                <Form.Item>
                    <h3>{labels[0]}</h3>
                    <Input 
                        name="title"
                        maxLength={TITLE_MAX_CHARS} 
                        onChange={this.handleOnChange.bind(this)}
                        placeholder="This is the title of the blog entry"
                    />
                    <small style={{fontSize: '1em'}}>{this.props.oldTitle}</small> <br />
                    <small>Remaining characters: {this.state.titleCharsLeft}</small>
                </Form.Item>
                <Form.Item>
                    <h3>{labels[1]}</h3>
                    <TextArea 
                        name="desc"
                        maxLength={DESC_MAX_CHARS}
                        onChange={this.handleOnChange.bind(this)}  
                        placeholder="This will be shown as a preview for the complete entry"
                    />
                    <small style={{fontSize: '1em'}}>{this.props.oldDesc}</small> <br />
                    <small>Remaining characters: {this.state.descCharsLeft}</small>
                </Form.Item>
                <Form.Item>
                    <h3>{labels[2]}</h3>
                    <FroalaEditor
                        name="body"
                        tag="textarea"
                        model={this.state.body}
                        onModelChange={this.handleModelChange}
                        config={{
                            imageUploadURL: IMAGE_UPLOAD_URL,
                            imageUploadMethod: 'POST',
                            placeholderText: 'Start writing!'
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <h3>{labels[3]}</h3>
                    <input type="file" name="coverImage" onChange={this.handleOnChange.bind(this)} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        )

        if(this.state.isLoading) {form = <Spin size="large"/>}

        return(
            <div>
                {form}
            </div>
        )
    }
}

export default FormBlog

// TODO: Try to implement cover image upload using AntD
/*
<Upload
    name="coverImage"
    onChange={this.handleUpload}
>
    <Button>
        <Icon type="upload"/> Click to upload
    </Button>
</Upload>

handleUpload(info) {
    if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
    }
}

*/