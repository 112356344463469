import React,{Component} from 'react';
import { Layout, Menu, Breadcrumb, Icon, Button } from 'antd';
import { Link, withRouter } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class CustomLayout extends Component{

	state = {
		collapsed: false,
		loggedOff: false
	};

	onCollapse = collapsed => {
		//console.log(collapsed);
		this.setState({ collapsed });
	};

	onLogout = () => {
		localStorage.removeItem('auth')
		this.setState({loggedOff:true})
	}

	render(){
	
		let login = <Menu.Item key="13" to="/login/"><Icon type="login" /><span>Login</span></Menu.Item>

		if(localStorage.getItem('auth')) {
			login = <Menu.Item key="13"><Button onClick={this.onLogout} type="primary" block><b>Logout</b></Button></Menu.Item>
		}

		if(this.state.loggedOff) {
			window.location.assign('https://panel.kuara-lite.com');
		}

		return (
			<Layout style={{ minHeight: '100vh' }}>
				<Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
					<div className="logo" />
					<Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
						{/* === HOME === */}
						<Menu.Item key="1">
							<Link to="/"></Link>
							<Icon type="home" /> {/* previously pie-chart */}
							<span>Home</span>
						</Menu.Item>

						{/* === PRODUCTS === */}
						<SubMenu
							key="sub1"
							title={
								<span>
									<Icon type="shopping-cart" />
									<span>Products</span>
								</span>
							}
						>
							{/* --- PRODUCTS LIST --- */}
							<Menu.Item key="2">
								<Link to="/">
									<span>
										<Icon type="profile" />
										<span>List</span>
									</span>
								</Link>
							</Menu.Item>
							{/* --- PRODUCTS CREATE --- */}
							<Menu.Item key="3">
								<Link to="/productcreate/">
									<span>
										<Icon type="plus" />
										<span>Create</span>
									</span>
								</Link>
							</Menu.Item>
							{/* --- PRODUCTS IMAGE --- */}
							<Menu.Item key="4">
								<Link to="/imagecreate/">
								<span>
								<Icon type="file-image" />
								<span>Image</span>
								</span>
								</Link>
							</Menu.Item>
							{/* --- PRODUCTS ICON --- */}
							<Menu.Item key="5">
								<Link to="/productcreate/">
								<span>
								<Icon type="code-sandbox" />
								<span>Icon</span>
								</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* === CATEGORIES === */}
						<SubMenu
							key="sub2"
							title={
								<span>
									<Icon type="build" />
									<span>Categories</span>
								</span>
							}
						>
							{/* --- CATEGORIES LIST --- */}
							<Menu.Item key="6">
								<Link to="/category/">
									<span>
										<Icon type="profile" />
										<span>List</span>
									</span>
								</Link>
							</Menu.Item>
							{/* --- CATEGORIES CREATE --- */}
							<Menu.Item key="7">
								<Link to="/categoriescreate/">
									<span>
										<Icon type="plus" />
										<span>Create</span>
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* === PROJECTS === */}
						<SubMenu
							key="sub3"
							title={
								<span>
									<Icon type="file-protect" />
									<span>Projects</span>
								</span>
							}
						>
							{/* --- PRJECTS LIST --- */}
							<Menu.Item key="8">
								<Link to="/project/">
									<span>
										<Icon type="profile" />
										<span>List</span>
									</span>
								</Link>
							</Menu.Item>
							{/* --- PROJECTS CREATE --- */}
							<Menu.Item key="9">
								<Link to="/projectcreate/">
									<span>
										<Icon type="plus" />
										<span>Create</span>
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* === BLOG === */}
						<SubMenu
							key="sub4"
							title={
								<span>
									<Icon type="edit" />
									<span>Blog</span>
								</span>
							}
						>
							{/* --- BLOG LIST --- */}
							<Menu.Item key="10">
								<Link to="/blog/">
									<span>
										<Icon type="profile" />
										<span>List</span>
									</span>
								</Link>
							</Menu.Item>
							{/* --- BLOG CREATE --- */}
							<Menu.Item key="11">
								<Link to="/blog/create/">
									<span>
										<Icon type="plus" />
										<span>Create</span>
									</span>
								</Link>
							</Menu.Item>
						</SubMenu>

						{/* === CONTACTO === */}
						<Menu.Item key="12" to="/contacto">
							<Icon type="contacts"/>
							<span>Contacto</span>
						</Menu.Item>
						
						{/* === LOGIN === */}
						{login}
					</Menu>
				</Sider>

				<Layout>
					<Header style={{ background: '#fff', padding: 0 }} />
					<Content style={{ margin: '0 16px' }}>
						<Breadcrumb style={{ margin: '16px 0' }}>
							<Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
							<Breadcrumb.Item><Link to="/">List</Link></Breadcrumb.Item>
						</Breadcrumb>
						<div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
							{this.props.children}
						</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
				</Layout>
			</Layout>
		);
	}
}
export default withRouter(CustomLayout);
